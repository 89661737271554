import React, { useState } from 'react';
import axios from 'axios';
import {
  Card, Input, DatePicker,
  Button, message,
  Form, Select,
  Radio, Modal,
  TimePicker, Row, Col, Popover,
} from 'antd';
import moment from 'moment-timezone';
import { useMutation } from 'react-query';
import Qty from 'js-quantities';
import { InfoCircleOutlined } from '@ant-design/icons';

import { getCognitoAccessToken } from '../../services/user.service';
import './index.scss';
import MapLocation from './MapLocation';

const { Option } = Select;

const units = ['in', 'ft', 'm', 'cm'];

const COLLECTION_STATUS = 'active';

const IceThickness = () => {
  const [marker, setMarker] = useState({
    latitude: 42.988,
    longitude: -86.257,
  });

  const [inputUnits, setInputUnits] = useState({
    ice_thickness: units[0],
    surface_snow_thickness: units[0],
  });

  const [form] = Form.useForm();

  const handleUnitChange = (inputName, value) => {
    setInputUnits((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const submitObservation = async (observation) => {
    const accessToken = await getCognitoAccessToken();

    const response = await axios.post(
      `${process.env.REACT_APP_API}v1/independent-obs`,
      observation,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response.data;
  };

  const mutation = useMutation(submitObservation, {
    onSuccess: () => {
      form.resetFields();
      Modal.success({
        title: 'Data Submitted Successfully',
        content: 'Thank you for submitting ice thickness data!',
      });
    },
    onError: (error) => {
      console.error('Error:', error);
      Modal.error({
        title: 'An Error Has Occurred',
        content: 'Please try again.',
      });
    },
  });

  const handleSubmit = () => {
    const fieldValues = form.getFieldsValue();

    const obsDate = moment(fieldValues.obs_date).format('YYYY-MM-DD');
    const obsTime = moment(fieldValues.obs_time).format('HH:mm:ss');
    const obsTimestamp = moment(`${obsDate} ${obsTime}`, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ssZ');

    const latitude = Number(fieldValues.latitude);
    const longitude = Number(fieldValues.longitude);

    const canonicalUnit = 'm';
    // TODO: ^ get from parameter configuration API

    const iceThicknessObs = {
      obs_timestamp: obsTimestamp,
      longitude,
      latitude,
      parameter_id: fieldValues.land_fast ? 4 : 1, // TODO: get from parameter configuration API
      value: Qty(Number(fieldValues.ice_thickness), inputUnits.ice_thickness).to(canonicalUnit).scalar,
      depth: 0,
      collection_status: COLLECTION_STATUS,
    };

    const snowThicknessObs = {
      obs_timestamp: obsTimestamp,
      longitude,
      latitude,
      parameter_id: 3,
      value: fieldValues.surface_snow_thickness ? Qty(Number(fieldValues.surface_snow_thickness), inputUnits.surface_snow_thickness).to(canonicalUnit).scalar : 0,
      depth: 0,
      collection_status: COLLECTION_STATUS,
    };

    // TODO: if "Snow Cover?"" is unchecked, submit 0 for surface snow thickness? or omit the obs entirely?

    const postData = {
      obs: [
        iceThicknessObs,
      ],
    };

    if (fieldValues.snow_cover && fieldValues.surface_snow_thickness) {
      postData.obs.push(snowThicknessObs);
    }

    mutation.mutate(postData);
  };

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const setLocation = (location) => {
    setMarker(location);
    form.setFieldsValue({
      latitude: location.latitude,
      longitude: location.longitude,
    });
  };

  const [snowCover, setSnowCover] = useState(false);

  const onChange = (changedFields, allFields) => {
    console.log('allFields', allFields);

    // Show/hide surface snow thickness field based on snow cover checkbox
    if (changedFields.length > 0 && changedFields[0].name.includes('snow_cover')) {
      const snowCover = changedFields[0].value;
      setSnowCover(snowCover);
    }

    // Update marker location if latitude or longitude fields are changed
    if (
      changedFields.length > 0
      && changedFields[0].name.includes('latitude')
      && !Number.isFinite(changedFields[0].value)
      && changedFields[0].value >= -90
      && changedFields[0].value <= 90
    ) {
      setMarker((prev) => ({
        ...prev,
        latitude: Number(changedFields[0].value),
      }));
    } else if (
      changedFields.length > 0
      && changedFields[0].name.includes('longitude')
      && !Number.isFinite(changedFields[0].value)
      && changedFields[0].value >= -180
      && changedFields[0].value <= 180
    ) {
      setMarker((prev) => ({
        ...prev,
        longitude: Number(changedFields[0].value),
      }));
    }
  };

  // TODO: abstract this to a separate component
  const unitOptions = (inputName) => (
    <Select
      value={inputUnits[inputName]}
      className="unit-options"
      onChange={(value) => handleUnitChange(inputName, value)}
    >
      {units.map((unit) => (
        <Option key={unit} value={unit}>
          {unit}
        </Option>
      ))}
    </Select>
  );

  const oneYearAgo = moment().subtract(1, 'year');
  const now = moment();

  const disabledDate = (current) => {
    const endOfDay = now.clone().endOf('day');
    return current && (current.isBefore(oneYearAgo) || current.isAfter(endOfDay));
  };

  const disabledHours = () => {
    const now = moment();
    const selectedDate = form.getFieldValue('obs_date');
    if (selectedDate && selectedDate.isSame(now, 'day')) {
      return [...Array(24).keys()].filter((hour) => hour > now.hour());
    }
    return [];
  };

  const disabledMinutes = (selectedHour) => {
    const now = moment();
    const selectedDate = form.getFieldValue('obs_date');
    if (selectedDate && selectedDate.isSame(now, 'day') && selectedHour === now.hour()) {
      return [...Array(60).keys()].filter((min) => min > now.minute());
    }
    return [];
  };

  const validateLatitude = (_, value) => {
    if ((value === undefined || value === '')
      || (Number.isFinite(Number(value)) && Number(value) >= -90 && Number(value) <= 90)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid Latitude'));
  };

  const validateLongitude = (_, value) => {
    if ((value === undefined || value === '')
      || (Number.isFinite(Number(value)) && Number(value) >= -180 && Number(value) <= 180)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid Latitude'));
  };

  const validateThickness = (_, value) => {
    if (value && value > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a positive number.'));
  };

  const [locationLoading, setLocationLoading] = useState(false);

  const onClickCurrentLocation = () => {
    setLocationLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: Number(position.coords.latitude.toFixed(6)),
          longitude: Number(position.coords.longitude.toFixed(6)),
        });
        setLocationLoading(false);
      },
      (error) => {
        console.error('Error fetching location:', error);
        message.error('Error fetching location. Please enable browser Location Access or enter manually.', 5);
        setLocationLoading(false);
      },
    );
  };

  return (
    <div className="add-ice-thickness">
      <Card title="Add Ice Thickness Data">
        <Form
          onFieldsChange={onChange}
          form={form}
          onFinish={handleSubmit}
          requiredMark={false}
          layout="vertical"
          labelAlign="left"
          style={{
            maxWidth: 500,
            margin: '0 auto',
          }}
        >
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8}>
              <Form.Item
                label="Observation Date"
                name="obs_date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  placeholder="Select Date"
                  showToday
                  disabledDate={disabledDate}
                  className="ice-thickness-date-picker"
                  dropdownClassName="ice-thickness-date-picker-dropdown"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label={`Observation Time (${moment().tz(userTimeZone).format('z')})`}
                name="obs_time"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimePicker
                  showSecond={false}
                  placeholder="Select Time"
                  format="hh:mm a"
                  showNow
                  use12Hours
                  disabledHours={disabledHours}
                  disabledMinutes={disabledMinutes}
                  className="time-picker"
                  onChange={(time) => {
                    // although it's available in form.getFieldValues,
                    // the form item is not passing validation without manually setting the value
                    form.setFieldsValue({
                      obs_time: time,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8}>
              <Form.Item
                label="Latitude"
                name="latitude"
                required
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: validateLatitude,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Longitude"
                name="longitude"
                required
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: validateLongitude,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Button
                onClick={onClickCurrentLocation}
                loading={locationLoading}
                className="current-location-button"
              >
                Use Current Location
              </Button>
            </Col>
          </Row>
          <MapLocation marker={marker} />
          <Form.Item
            label="Ice Thickness"
            name="ice_thickness"
            rules={[
              {
                required: true,
              },
              {
                validator: validateThickness,
              },
            ]}
          >
            <Input addonAfter={unitOptions('ice_thickness')} type="number" className="number-input" />
          </Form.Item>
          <Form.Item
            label={(
              <span>
                Is the Ice Land-Fast?
                {' '}
                <Popover content="Land fast ice is stationary ice connected to the coast.">
                  <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                </Popover>
              </span>
            )}
            name="land_fast"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Is Snow Cover Present?"
            name="snow_cover"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {snowCover && (
            <Form.Item
              label="Surface Snow Thickness"
              name="surface_snow_thickness"
              rules={[
                {
                  required: true,
                },
                {
                  validator: validateThickness,
                },
              ]}
            >
              <Input addonAfter={unitOptions('surface_snow_thickness')} type="number" className="number-input" />
            </Form.Item>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={mutation.isLoading}
            className="submit-button"
            style={{
              background: 'rgb(246, 125, 40)',
              border: 0,
              fontSize: 18,
              fontWeight: 'bold',
              textShadow: 'none',
              height: 'auto',
              margin: '10px auto 0',
              display: 'block',
            }}
          >
            Submit
          </Button>
        </Form>
      </Card>
    </div>
  );
};

IceThickness.propTypes = {
};

IceThickness.defaultProps = {
};

export default IceThickness;
