import React, { useState, useEffect } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import 'mapbox-gl/dist/mapbox-gl.css';

import { mapStyle } from '../../components/custom-map/constant';

import Pin from './Pin';
import './index.scss';

const initialViewState = {
  latitude: 45,
  longitude: -85,
  zoom: 4.5,
};

const MapLocation = ({ marker }) => {
  const [viewport, setViewport] = useState(initialViewState);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    setViewport({
      ...viewport,
      zoom: 9,
      latitude: marker.latitude,
      longitude: marker.longitude,
    });
  }, [marker]);

  return (
    <div
      className="map-container"
      style={{
        width: isMobile ? '100%' : 600,
        height: 400,
      }}
    >
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={initialViewState}
        style={{ width: '100%', height: '100%' }}
        height="100%"
        maxZoom={20}
        maxPitch={0}
        dragRotate={false}
        touchRotate={false}
        onViewportChange={(viewport) => setViewport(viewport)}
        mapStyle={mapStyle}
      >
        <Marker
          longitude={marker.longitude}
          latitude={marker.latitude}
          anchor="bottom"
          // draggable
          // onDragEnd={onMarkerDragEnd}
        >
          <Pin />
        </Marker>
        <NavigationControl
          style={{
            position: 'absolute',
            bottom: 50,
            left: 'calc(100% - 40px)',
          }}
        />
      </ReactMapGL>
    </div>
  );
};

MapLocation.propTypes = {
  marker: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
};

export default MapLocation;
