import * as React from 'react';

const Pin = () => (
  <div
    style={{
      fontSize: 35,
      transform: 'translate(-50%, -100%)',
      lineHeight: 1,
    }}
  >
    📍
  </div>
  // TODO: Replace this with a custom SVG pin if needed?
  // <svg
  //   height="24"
  //   viewBox="0 0 24 24"
  //   style={{
  //     cursor: 'pointer',
  //     fill: 'red',
  //     transform: 'translate(-50%, -50%)', // Ensures the pin is centered
  //   }}
  // >
  //   <path d="M12 2C8.13 2 5 5.13 5 9c0 3.54 5 11 7 11s7-7.46 7-11c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z" />
  // </svg>
);
export default Pin;
